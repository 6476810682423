<template>
  <div id="members-transfer-assign">
    <v-card>
      <v-form ref="form2" v-model="valid2" lazy-validation>
        <v-card-title class="text-h5">
          <h2>
            {{ PageTitle }}
          </h2>
        </v-card-title>

        <v-card-subtitle class="text-h5">
          <h4>
            {{ PageDescription }}
          </h4>
        </v-card-subtitle>
        <v-footer :fixed="true" :absolute="false" :padless="true">
          <v-card flat tile width="100%" color="#8950FC">
            <v-container>
              <v-row wrap>
                <v-col align="center" cols="6" md="6">
                  <h3 class="text-white">
                    Total Members : {{ TotalMembers }} /
                    {{ rows.RemainingCount }}
                  </h3>
                </v-col>
                <v-col align="center" cols="6" md="6">
                  <v-btn
                    :loading="SubmitFlag"
                    color="success"
                    class="mr-4"
                    @click="submitConfirmAlert"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-footer>
        <v-container class="py-0">
          <v-speed-dial
            v-if="FloatingButtonFlag"
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            :fixed="true"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="blue darken-2" dark fab>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-account-circle </v-icon>
              </v-btn>
            </template>
          </v-speed-dial>
        </v-container>

        <v-card-text>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn color="success" class="mr-4" @click="backPage">
                  Back
                </v-btn>
                <!-- <v-btn color="success" class="mr-4" @click="confirmAlert">
                  Alert
                </v-btn> -->
              </v-col>
            </v-row>
          </v-container>
          <br />
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <br />
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
            <br /><br />
          </div>
          <v-container class="py-0" v-if="StartupLoadingFlag">
            <v-skeleton-loader
              v-bind="attrs"
              type="table-tbody"
            ></v-skeleton-loader>
          </v-container>
          <v-container class="py-0" v-if="!StartupLoadingFlag">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h2 class="title1">
                  {{ rows.YearName }} - {{ rows.ContributionTypeDescription }}
                </h2>
              </v-col>
            </v-row>
            <v-row wrap>
              <!-- <v-col align="center" cols="12" md="3">
                <v-card color="#B9004F" dark hover>
                  <v-card-title class="card-title"> Last Date </v-card-title>
                  <v-card-subtitle>
                    <p class="card-subtitle1">
                      {{ rows.ContributionEndDateTxt }}
                    </p>
                  </v-card-subtitle>
                </v-card>
              </v-col> -->

              <v-col align="center" cols="12" md="4">
                <v-card color="#ff6b6b" dark hover>
                  <v-card-title class="card-title">
                    Subscription paid
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.PaidCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4">
                <v-card color="success" dark hover>
                  <v-card-title class="card-title">
                    Totally selected
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.SelelctedCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4">
                <v-card color="warning" dark hover>
                  <v-card-title class="card-title">
                    Please select
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.RemainingCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <br />

          <v-container class="notes">
            <p>Important Notes:</p>
            <ol>
              <li>
               Select the Zone and LO to which the member is going to be transferred.
              </li>
              <li>
               Choose the member from the current year members list shown below.
              </li>
              <li>
                Enter your approval description and click on submit to initiate the transfer request.
              </li>
            </ol>
            <p>Conditions for a successful transfer:</p>
            <ol>
              <li>
               The member should have paid subscription for the current year and his/her ID should have been updated in the current year’s members list.
              </li>
              <li>
              The LO President to which the member is going to be transferred should confirm the member transfer request initiated in his/her Members Portal
              </li>
              <li>
                When both LO Presidents have confirmed, the NHQ has to finally approve to make the transfer successful.
              </li>
            </ol>
          </v-container>
          <v-row wrap>
            <v-col cols="12" md="3">
              <h6><span class="text-danger">*</span> To Zone</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="ToZoneCodeOptionsLoading"
                :items="ToZoneCodeOptions"
                :rules="ToZoneCodeRules"
                v-model="ToZoneCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <h6><span class="text-danger">*</span> To LOM</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="ToLomCodeOptions"
                :loading="ToLomCodeOptionsLoading"
                :rules="ToLomCodeRules"
                v-model="ToLomCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" v-if="SecondHalfFlag">
              <h6><span class="text-danger">*</span> From Half</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :items="FromYearHalfOptions"
                :rules="FromYearHalfRules"
                v-model="FromYearHalf"
                dense
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12">
              <h6><span class="text-danger">*</span> Approval Description</h6>
              <v-text-field
                v-model="ApprovalDescription"
                :rules="ApprovalDescriptionRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <br />

          <v-card flat>
            <p></p>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="text-primary">
                  Select Members from the Current half paid
                </h3>
              </v-col>
            </v-row>
            <v-container
              class="py-0"
              v-if="!LoadingFlag && ResultFlag && tableData.length == 0"
            >
              <br />
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <h4>No members found.</h4>
                </v-col>
              </v-row>
            </v-container>

            <v-container class="py-0" v-if="tableData.length > 0">
              <br />
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <h3>{{ tableData.length }} members found</h3>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search records here"
                    single-line
                    hide-details
                  ></v-text-field>
                  <br />
                  <v-data-table
                    class="elevation-1"
                    v-model="selected1"
                    :headers="tableColumns"
                    :items="tableData"
                    :search="search"
                    :show-select="tableOptions.ShowSelectFlag"
                    :loading="TableLoadingFlag"
                    :item-key="tableOptions.ItemKey"
                    :single-select="tableOptions.SingleSelectFlag"
                    :items-per-page="tableOptions.ItemsPerPage"
                    :footer-props="tableOptions.FooterProps"
                  >
                    <template v-slot:item.ActiveStatusTxt="{ item }">
                      <v-chip
                        :color="getActiveStatusColor(item.ActiveStatusTxt)"
                        draggable
                        dark
                        >{{ item.ActiveStatusTxt }}</v-chip
                      >
                    </template>
                    <template v-slot:item.ProfilePic="{ item }">
                      <img width="100" height="100" :src="item.ProfilePic" />
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-2"
                            icon
                            size="25px"
                            color="blue"
                            @click="previewData(item, e)"
                            v-bind="attrs"
                            v-on="on"
                            v-if="item.ActiveStatus == 2"
                          >
                            mdi-clipboard-text-search
                          </v-icon>
                        </template>
                        <span> Preview </span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            icon
                            size="25px"
                            color="warning"
                            @click="editAlert(item, e)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-file-document-edit
                          </v-icon>
                        </template>
                        <span> Edit </span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <br /><br />
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import AddNewMember from "@/view/pages/erp/payment/AddNewMember.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    AddNewMember,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      SecondHalfFlag: false,

      TabDisable1: false,
      TabDisable2: false,

      rows: [],
      tableColumns: [],
      tableOptions: {},
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},
      LomPaymentId: "",
      tableData: [],

      selected1: [],
      TotalMembers: 0,

      ToZoneCodeRules: [(v) => !!v || "Zone is required"],
      ToZoneCode: "",
      ToZoneCodeOptions: [],
      ToZoneCodeOptionsLoading: false,

      ToLomCodeRules: [(v) => !!v || "LOM is required"],
      ToLomCode: "",
      ToLomCodeOptions: [],
      ToLomCodeOptionsLoading: false,

      FromYearHalfRules: [],
      FromYearHalf: "",
      FromYearHalfOptions: [
        { value: "", text: "--Select From Half--" },
        { value: "1", text: "First Half" },
        { value: "2", text: "Second Half" },
      ],

      ApprovalDescription: "",
      ApprovalDescriptionRules: [
        (v) => !!v || "Approval description is required"
      ],

      YearId: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;
        this.SecondHalfFlag = tr.SecondHalfFlag;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log({ ZoneCode });
        this.ZoneCode = ZoneCode;

        var LomCode = this.$session.get("LomId");
        LomCode = LomCode == (null || undefined) ? "" : LomCode;
        console.log({ LomCode });
        this.LomCode = LomCode;

        this.getTableRecords();
        this.getZoneCodeOptions();
      }
    },
    selected1: function () {
      console.log("watch selected1");
      var length = this.selected1.length;
      this.TotalMembers = parseInt(length);
      console.log("TotalMembers=" + TotalMembers);
    },
    ToZoneCodeOptions: function () {
      console.log("watch ToZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToZoneCodeOptionsLoading = false;
    },
    ToZoneCode: function () {
      console.log("watch ToZoneCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = true;
      this.getLomCodeOptions("ToLomCode", "ToLomCodeOptions", this.ToZoneCode);
    },
    ToLomCodeOptions: function () {
      console.log("watch ToLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = false;
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "members_transfer",
        Action: "create_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    submitConfirmAlert() {
      Swal.fire({
        title: "Do you want to continue?",
        text: "Please verify all the information before proceeding.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitForm();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.CurrentYearId;
      if (CurrentYearId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = "ToZoneCode";
        var selectbox1_destination = "ToZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ToZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      var CurrentYearId = this.CurrentYearId;
      if (CurrentYearId != "") {
        var selectbox1_source = "ToLomCode";
        var selectbox1_destination = "ToLomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomPaymentId = this.LomPaymentId;
      console.log("LomPaymentId=" + LomPaymentId);

      if (LomPaymentId != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-payment/show";
        var upload = {
          UserInterface: 1,
          LomPaymentId: LomPaymentId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.StartupLoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.rows = records;

              thisIns.getSubscriptionMembers();
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
    getSubscriptionMembers() {
      console.log("getSubscriptionMembers called");

      //   this.resetMessageTxt();
      this.selected1 = [];
      this.tableData = [];
      this.tableColumns = [];

      var LomCode = this.rows.LomId;
      var YearCode = this.rows.YearId;
      var YearHalf = this.rows.YearHalf;
      var MemberType = this.rows.MemberTypeId;
      console.log(
        "LomCode=" +
          LomCode +
          ", YearCode=" +
          YearCode +
          ", YearHalf=" +
          YearHalf +
          ", MemberType=" +
          MemberType
      );

      if (LomCode != "" && YearCode != "" && YearHalf != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-members/list";
        var upload = {
                    UserInterface: 1,
            LomCode: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        // thisIns.pageLoadingOn()
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            var TableContent = response.data.records;
            console.log({TableContent});

            var options = TableContent.TableOptions;
            console.log({ options });
            thisIns.tableOptions = options;

            if (flag == 1) {
              thisIns.tableColumns = TableContent.TableHeader;
              thisIns.tableData = TableContent.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "LOM should not be empty.";
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      var RemainingCount = this.rows.RemainingCount;
      console.log("RemainingCount=" + RemainingCount);

      var Members = this.selected1;
      var n1 = Members.length;
      console.log("n1=" + n1 + ", Members=" + JSON.stringify(Members));

      var TotalCount = parseInt(n1);
      console.log("TotalCount=" + TotalCount);

            
      var YearCode = this.rows.YearId;
      var YearHalf = this.rows.YearHalf;
      var LomCode = this.rows.LomId;
      console.log({YearCode});
      console.log({YearHalf});
      console.log({LomCode});

      // this.rows = [];

      if (
        (!this.SecondHalfFlag || this.FromYearHalf != "") &&
        validate1 &&
        TotalCount <= RemainingCount &&
        RemainingCount > 0 &&
        TotalCount > 0
      ) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/assign/transfer-members";
        var upload = {
          UserInterface: 1,
          LomPaymentId: this.LomPaymentId,
          FromZoneCode: this.ZoneCode,
          FromLomCode: LomCode,
          ToZoneCode: this.ToZoneCode,
          ToLomCode: this.ToLomCode,
          FromYearHalf: this.FromYearHalf,
          SecondHalfFlag: this.SecondHalfFlag,
          ApprovalDescription: this.ApprovalDescription,
          Members: Members,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.$router.push("/members/subscription/list");
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (this.SecondHalfFlag && this.FromYearHalf == "") {
          message += "From Half is should't be empty. ";
        }
        if (TotalCount <= 0) {
          message += "Atleast one member is required to transfer. ";
        }
        if (TotalCount > RemainingCount) {
          message +=
            "You can select within " + RemainingCount + " members only. ";
        }
        if (RemainingCount == 0) {
          message += "Already you selected all members for this subscription. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
  },
  beforeMount() {
    console.log(this.$route.query.id);
    this.LomPaymentId = this.$route.query.id;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#members-transfer-assign {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .card-title {
    font-size: 25px;
    font-family: "Lora", serif;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
  }
  .card-subtitle {
    font-size: 60px;
    font-family: "Lobster", cursive;
    color: white;
    text-align: center;
  }
  .card-subtitle1 {
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    color: white;
    text-align: center;
    line-height: 40px;
  }

  .title1 {
    font-weight: bold;
    font-family: "Libre Baskerville", serif;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .notes {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  }
  .notes {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  }
}
</style>